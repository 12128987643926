import {
  GoogleInitOptions,
  GoogleLoginProvider,
  GoogleSigninButtonDirective,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const fbLoginOptions = {
  enable_profile_selector: true,
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false,
  scopes: ['profile', 'email'],
};

const config: SocialAuthServiceConfig = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        '823098937866-6b5uje7lhb53b9elffnbeoebnabak69d.apps.googleusercontent.com',
        googleLoginOptions,
      ), //Google-OAuth-Client-Id
    },
  ],
};

export function provideConfig() {
  return config;
}

@NgModule({
  imports: [CommonModule, SocialLoginModule, GoogleSigninButtonModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: provideConfig,
    },
  ],
  exports: [GoogleSigninButtonDirective],
})
export class SFSocialLoginModule {}
