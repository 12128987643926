import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TeamAbstract } from 'src/models/team/team';
import { ConnectionService } from 'src/services/connection.service';
import { ResourceService } from 'src/services/resource.service';
import { Group } from './group';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends ResourceService<Group> {
  constructor(private connectionService: ConnectionService) {
    super(connectionService, 'group', Group);
  }

  addGroupToTeam(group: Group, team: TeamAbstract): Observable<Group> {
    group.teamId = team.id;
    return this.connection
      .post(this.endpoint + '/add-to-team', this.cleanGroup(group), {
        handleLoading: true,
        loadingMsg: `Adding ${group.name} to ${team.name}`,
      })
      .pipe(map(this.mergeUnMatched));
  }

  updateGroup(group: Group): Observable<Group> {
    return this.connection
      .post(this.endpoint + '/update', this.cleanGroup(group))
      .pipe(map(this.mergeUnMatched));
  }

  renameGroup(groupId: number, name: string): Observable<Group> {
    return this.connection.post(
      this.endpoint + '/rename-group?groupId=' + groupId,
      name,
    );
  }
  deleteGroup(groupId: number): Observable<Boolean> {
    return this.connection.get(this.endpoint + '/delete?groupId=' + groupId, {
      handleLoading: true,
      loadingMsg: 'Deleting Group...',
    });
  }

  calcGroupPrice(
    groupFormGroup: UntypedFormGroup,
    handleError: boolean = true,
  ) {
    if (groupFormGroup.valid == false) return;
    return this.connection
      .post(
        this.endpoint + '/calc-price',
        this.cleanGroup(groupFormGroup.value),
        { handleError: handleError },
      )
      .pipe(
        map(this.mergeUnMatched),
        tap((group: Group) => this.updateGroupFGPricing(groupFormGroup, group)),
      );
  }

  updateGroupFGPricing(groupFG: UntypedFormGroup, groupWithPrice: Group) {
    groupFG
      .get('extendedMatchingCandidatesCount')
      .setValue(groupWithPrice.extendedMatchingCandidatesCount);
    groupFG
      .get('exactMatchingCandidatesCount')
      .setValue(groupWithPrice.exactMatchingCandidatesCount);
    groupFG.get('totalPriceRange').setValue(groupWithPrice.totalPriceRange);
  }

  cleanGroup(group: Group): Group {
    delete group['currentStep'];
    delete group['statusReadable'];
    this.separateUnMatched(group);
    group = this.handlePromoCode(group);
    return group;
  }

  separateUnMatched(group): Group {
    group['unmatchedJob'] = group.job?.unhandled
      ? (({ unhandled, ...o }) => o)(group.job)
      : null;
    group['job'] = group.job?.unhandled ? null : group.job;
    group['unmatchedSkills'] = group.skills
      .filter((skill) => skill && skill.unhandled)
      .map(({ unhandled, ...skill }) => skill);
    group['skills'] = group.skills
      .filter((skill) => skill && !skill.unhandled)
      .map(({ unhandled, ...skill }) => skill);
    return group;
  }

  mergeUnMatched(group): Group {
    if (group.unmatchedJob) {
      group.job = group.unmatchedJob;
      group.job.unhandled = true;
    }
    if (group.unmatchedSkills) {
      group.unmatchedSkills.forEach((element) => {
        element.unhandled = true;
        group.skills.push(element);
      });
    }
    return group;
  }

  handlePromoCode(group: Group): Group {
    let usedPromo = group.usedPromo;
    if (usedPromo) {
      group.promoCode = { name: group.promoCode };
    } else group.promoCode = null;

    delete group['usedPromo'];
    return group;
  }
}
