import { animate, style, transition, trigger } from '@angular/animations';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject, filter, first, map, takeUntil } from 'rxjs';
import { RoleType } from 'src/models/appUser/appUser';
import { Company } from 'src/models/company/company';
import { CompanyService } from 'src/models/company/company.service';
import { TalentProfileService } from 'src/models/talentProfile/talent.profile.service';
import { TalentProfile } from 'src/models/talentProfile/talentProfile';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { HeaderLink, headerDataMap } from '../header-data';
import { HeaderServices } from '../header.service';

@Component({
  selector: 'app-header-profile-container',
  templateUrl: './header-profile-container.component.html',
  styleUrls: ['./header-profile-container.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate(
          '0.5s ease-out',
          style({ opacity: '1', transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'translateY(0%)' }),
        animate(
          '0.3s ease-out',
          style({ opacity: '0', transform: 'translateY(-5%)' }),
        ),
      ]),
    ]),
  ],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class HeaderProfileContainerComponent implements OnInit {
  @ViewChild('companyContainer') companyContainer: ElementRef;
  @ViewChild('profileOptions') profileOptionsOverlay: TemplateRef<any>;
  @ViewChild('companyOptions') companyOptionsOverlay: TemplateRef<any>;

  dropDownLinks: HeaderLink[] = [];
  optionsMenuOverlayRef: OverlayRef;

  currentUserCompany: Company;
  careersLink: string;
  pipelinesLink: string;
  companyProfileLink: string;

  terminateSubs$: Subject<void> = new Subject();

  constructor(
    public headerServices: HeaderServices,
    private authenticationService: AuthenticationService,
    private overlay: Overlay,
    private talentProfileService: TalentProfileService,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.getDropDownLinksForCurrentUser();
    this.addPublicProfileLinkForSkilled();
  }
  private getDropDownLinksForCurrentUser() {
    this.authenticationService
      .getUserInfo()
      .pipe(
        takeUntil(this.terminateSubs$),
        map((userInfo) => userInfo.roles?.[0]?.role),
        filter((userRole) => userRole != null),
        map((userRole) => headerDataMap.get(userRole).dropDownLinks),
      )
      .subscribe((dropdownLinks) => {
        this.dropDownLinks = dropdownLinks;
      });
  }

  private addPublicProfileLinkForSkilled() {
    this.authenticationService
      .getUserInfo()
      .pipe(
        takeUntil(this.terminateSubs$),
        map((userInfo) => userInfo.roles?.[0]?.role),
        filter((userRole) => userRole != null && userRole == RoleType.SKILLED),
        map((userRole) => headerDataMap.get(userRole).dropDownLinks),
      )
      .subscribe((dropdownLinks) => {
        dropdownLinks.map((link, index) => {
          if (link.url == '/profile/my-profile') {
            this.talentProfileService
              .getOrCreateProfile(false)
              .pipe(first())
              .subscribe((talentProfile: TalentProfile) => {
                if (!talentProfile.isProfilePublic)
                  this.dropDownLinks[index] = {
                    ...this.dropDownLinks[index],
                    name: 'Create public profile',
                  };
              });
            return link;
          }
        });
      });
  }

  routeToLink(link: string) {
    if (this.optionsMenuOverlayRef) this.optionsMenuOverlayRef.dispose();
    this.headerServices.routeToLink(link);
  }

  toggleOptionsList(referenceElement: HTMLElement) {
    if (this.optionsMenuOverlayRef && this.optionsMenuOverlayRef.hasAttached())
      this.closeOptionsList();
    else this.openOptionsList(referenceElement);
  }

  closeOptionsList() {
    if (this.optionsMenuOverlayRef) this.optionsMenuOverlayRef.dispose();
  }

  openOptionsList(referenceElement: HTMLElement) {
    if (this.optionsMenuOverlayRef && this.optionsMenuOverlayRef.hasAttached())
      return;
    let positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(referenceElement)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
          0,
          0,
        ),
      ]);
    const config = new OverlayConfig({
      positionStrategy: positionStrategy,
      minWidth: Math.max(referenceElement.offsetWidth, 180),
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    const overlayRef: OverlayRef = this.overlay.create(config);

    this.optionsMenuOverlayRef = overlayRef;
    const tempPortal = new TemplatePortal(
      this.profileOptionsOverlay,
      this._viewContainerRef,
    );
    overlayRef.attach(tempPortal);
  }

  openCompanyList() {
    if (this.optionsMenuOverlayRef && this.optionsMenuOverlayRef.hasAttached())
      return;
    let positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.companyContainer)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
          0,
          0,
        ),
      ]);
    const config = new OverlayConfig({
      positionStrategy: positionStrategy,
      minWidth: Math.max(this.companyContainer.nativeElement.offsetWidth, 180),
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    const overlayRef: OverlayRef = this.overlay.create(config);

    this.optionsMenuOverlayRef = overlayRef;
    const tempPortal = new TemplatePortal(
      this.companyOptionsOverlay,
      this._viewContainerRef,
    );
    overlayRef.attach(tempPortal);
  }

  ngOnDestroy() {
    this.terminateSubs$.next();
    this.terminateSubs$.complete();
  }
}
