import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AppUserNotificationServices } from 'src/models/appUserNotification/app-user-notification.service';
import { AppUserNotification } from 'src/models/appUserNotification/appUserNotification';
import { NotificationType } from 'src/models/notification/notification';
import { AuthenticationService } from 'src/services/authentication/authentication.service';

const ovarlayAnimationDuration = 300;
@Component({
  selector: 'app-header-feed-container',
  templateUrl: './header-feed-container.component.html',
  styleUrls: ['./header-feed-container.component.scss'],
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        style({ transform: 'translateY(-10%)', opacity: 0 }),
        animate(
          ovarlayAnimationDuration,
          style({ transform: 'translateY(0)', opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        animate(ovarlayAnimationDuration, style({ opacity: 0 })),
      ]),
    ]),
    trigger('slideDownQuick', [
      transition(':enter', [
        style({ transform: 'translateY(-10%)', opacity: 0 }),
        animate(150, style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        // style({transform: "translateY(-10%)" , opacity:0 }),
        animate(150, style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      // fade out when destroyed. this could also be written as transition('void => *')
      transition(
        ':leave',
        animate(250, style({ transform: 'translateX(100px)', opacity: 0 })),
      ),
    ]),
  ],
})
export class HeaderFeedContainerComponent implements OnInit {
  @ViewChild('feedContainer') feedBtnElement: ElementRef;
  @ViewChild('feedContainerOverlay') feedContainerOverlay: TemplateRef<any>;

  feedOverlayRef: OverlayRef;

  opend: boolean = false;
  canOpenOverlay: boolean = true;
  terminateSubs: Subject<any> = new Subject();
  appUserNotifications: AppUserNotification[];
  unFormatedappUserNotifications: AppUserNotification[];
  iconsPath: String = '../../../assets/icons/';
  unReadCount: number;
  loadingFeed: WritableSignal<boolean> = signal(false);

  constructor(
    private overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private dashboardServices: AppUserNotificationServices,
    private router: Router,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.authService
      .getUserInfo()
      .pipe(takeUntil(this.terminateSubs))
      .subscribe((userInfo) => {
        this.unReadCount = userInfo.unreadNotificationsCount;
      });
  }

  preprocessFeed(appUserNotifications: AppUserNotification[]) {
    appUserNotifications.forEach((appUserNotification) => {
      this.preprocessDate(appUserNotification);
      this.preprocessType(appUserNotification);
    });
  }

  preprocessDate(appUserNotification: AppUserNotification) {
    let yesterday = new Date().getTime() - 24 * 1000 * 60 * 60;
    let notificationDate = parseInt(
      appUserNotification.createDateTime.toString(),
    );
    if (notificationDate > yesterday) {
      // notification is less than 24 hours ago
      let minsAgo = Math.floor(
        (new Date().getTime() - notificationDate) / (1000 * 60),
      );
      if (minsAgo < 60) {
        appUserNotification.createDateTime =
          minsAgo.toString() + ' minutes ago';
      } else {
        let hoursAgo = Math.floor(minsAgo / 60);
        appUserNotification.createDateTime = hoursAgo.toString() + 'h ago';
      }
      appUserNotification['formatDate'] = false;
    } else appUserNotification['formatDate'] = true;
  }

  preprocessType(appUserNotification: AppUserNotification) {
    let type = appUserNotification.notification.type;
    let iconPath: String;
    switch (type) {
      case NotificationType.BONUS:
        iconPath = this.iconsPath + 'small-coin.svg';
        break;
      case NotificationType.JOBS:
        iconPath = this.iconsPath + 'small-suitcase.svg';
        break;
      case NotificationType.NEWS:
        iconPath = this.iconsPath + 'small-speaker.svg';
        break;
      case NotificationType.SETTINGS:
        iconPath = this.iconsPath + 'small-settings.svg';
        break;

      case NotificationType.UPDATES:
        iconPath = this.iconsPath + 'small-speaker.svg';
        break;
    }

    appUserNotification['iconPath'] = iconPath;

    type = type.charAt(0) + type.substring(1).toLowerCase();
    appUserNotification.notification.type = type;

    appUserNotification['class'] = type.toLowerCase();
  }

  deleteFeedEntry(feedEntry: AppUserNotification) {
    this.appUserNotifications = this.appUserNotifications.filter(
      (obj) => obj !== feedEntry,
    );
    this.dashboardServices
      .deleteFeedEntry(feedEntry.id)
      .pipe(takeUntil(this.terminateSubs))
      .subscribe((res) => {
        if (!feedEntry.viewed) this.unReadCount--;
      });
  }

  markFeedAsRead() {
    this.appUserNotifications = this.appUserNotifications.map(
      (notification) => {
        notification.viewed = true;
        return notification;
      },
    );
    this.unReadCount = 0;
  }

  toggleFeed() {
    if (this.feedOverlayRef && this.feedOverlayRef.hasAttached())
      this.closeFeedOverlay();
    else this.openFeedOverlay();
  }

  closeFeedOverlay() {
    if (this.feedOverlayRef) {
      this.feedOverlayRef.dispose();
    }
    this.dashboardServices
      .markFeedAsRead()
      .pipe(takeUntil(this.terminateSubs))
      .subscribe((res) => {
        this.markFeedAsRead();
      });
  }

  openFeedOverlay() {
    if (this.feedOverlayRef && this.feedOverlayRef.hasAttached()) return;
    this.loadUserFeedIfNeeded();
    let positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.feedBtnElement)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
          0,
          10,
        ),
      ]);
    const config = new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    const overlayRef: OverlayRef = this.overlay.create(config);

    this.feedOverlayRef = overlayRef;
    const tempPortal = new TemplatePortal(
      this.feedContainerOverlay,
      this._viewContainerRef,
    );
    overlayRef.attach(tempPortal);
    overlayRef.backdropClick().subscribe(() => {
      this.closeFeedOverlay();
      overlayRef.dispose();
    });
  }

  loadUserFeedIfNeeded() {
    if (!this.appUserNotifications) {
      this.loadingFeed.set(true);
      this.dashboardServices
        .getUserFeed()
        .pipe(first())
        .subscribe((appUserNotifications: AppUserNotification[]) => {
          this.appUserNotifications = appUserNotifications;
          this.loadingFeed.set(false);
          this.unReadCount = 0;
          this.preprocessFeed(this.appUserNotifications);
        });
    }
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  ngOnDestroy() {
    this.terminateSubs.next(null);
    this.terminateSubs.complete();
  }
}
