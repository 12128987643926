<div
  *ngIf="isCurrentPlanFree"
  class="show-on-shrink-flex shrank-card">
  <span class="f-10"> FREE </span>
  <app-svg-icon
    iconSrc="assets/icons/upgrade.svg"
    width="20px"
    color="var(--blue-600)" />
  <span class="f-8"> UPGRADE </span>
</div>
<div
  class="shrinkable"
  [ngSwitch]="companyPackageCardLayout">
  <div
    *ngSwitchCase="CompanyPackageCardLayout.FULL_CARD"
    class="package-card">
    <div class="package-card-header">
      <ng-container *ngIf="isLoading">
        <app-skeleton-loading height="30px"></app-skeleton-loading>
      </ng-container>
      <ng-container *ngIf="!isLoading">{{ currentPlanName }}</ng-container>
    </div>
    <div class="f-12 f-neutral-500 margin-childs-vertical-4">
      <ng-container *ngIf="isLoading">
        <app-skeleton-loading
          margin="12px 0"
          height="12px"></app-skeleton-loading>
        <app-skeleton-loading
          margin="12px 0"
          height="12px"></app-skeleton-loading>
        <app-skeleton-loading height="14px"></app-skeleton-loading>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="isWithRecruitment">
          <app-labeled-progress-bar
            color="var(--blue-600)"
            [progressValue]="(remainingPostsCount / totalPostsCount) * 100" />
          <div>
            {{ remainingPostsText }}
          </div>
        </ng-container>
        <div *ngIf="nextResetDate">{{ resetDateLabel }}: {{ nextResetDate }}</div>
      </ng-container>
      <a
        mat-button
        class="btn-bk-blue-600 upgrade-btn"
        *ngIf="!isLoading"
        routerLink="/client/pricing">
        <img
          class="mg-r-0"
          src="assets/icons/upgrade.svg" />
        <span class="mg-l-10">UPGRADE NOW</span>
      </a>
    </div>
  </div>

  <div
    *ngSwitchCase="CompanyPackageCardLayout.SINGLE_ROW"
    class="d-flex align-items-center gap-3 flex-wrap">
    <div class="card-white current-plan-row">
      <div
        *ngIf="showLabel"
        class="card-tag"
        >Current plan</div
      >
      <ng-container *ngIf="isLoading">
        <app-skeleton-loading height="14px"></app-skeleton-loading>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <div>{{ currentPlanName }}</div>
        <div class="vertical-separator"></div>
        <div>{{ remainingPostsText }}</div>
        <ng-container *ngIf="nextResetDate">
          <div class="vertical-separator"></div>
          <div>{{ resetDateLabel }}: {{ nextResetDate }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
