import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SFServiceStatus } from 'src/models/sfService/sfService';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { CompanyJBPackageService } from './../../../../models/companyJBPackage/company-jb-package.service';
import { CompanyJBPackage } from './../../../../models/companyJBPackage/companyJBPackage';

export enum CompanyPackageCardLayout {
  FULL_CARD,
  SINGLE_ROW,
}

@Component({
  selector: 'app-company-package-card',
  templateUrl: './company-package-card.component.html',
  styleUrls: ['./company-package-card.component.scss'],
})
export class CompanyPackageCardComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() companyPackageCardLayout: CompanyPackageCardLayout =
    CompanyPackageCardLayout.FULL_CARD;
  @Input() withActions: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() companyPackage: CompanyJBPackage;

  @HostBinding('class') hostClass;

  CompanyPackageCardLayout = CompanyPackageCardLayout;
  isLoading: boolean = true;
  isCurrentPlanFree: boolean;
  hasFreeExclusiveAccess: boolean;
  currentPlanName: string;
  totalPostsCount: number;
  remainingPostsCount: number;
  remainingPostsText: string;
  nextResetDate: string;
  resetDateLabel: string;
  currentUserCompanyJBPackage$: Observable<CompanyJBPackage>;
  terminateSubs$: Subject<any> = new Subject();
  isWithRecruitment: boolean;
  constructor(
    private companyJBPackageService: CompanyJBPackageService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    if (this.companyPackage && this.authenticationService.isUserAdmin())
      this.updateCurrentPlan(this.companyPackage);
    else
      this.companyJBPackageService
        .getCurrentUserCompanyJBPackage()
        .pipe(takeUntil(this.terminateSubs$))
        .subscribe((companyJBPackage) => {
          this.updateCurrentPlan(companyJBPackage);
        });
  }

  private updateCurrentPlan(companyJBPackage: CompanyJBPackage) {
    this.companyPackage = companyJBPackage;
    this.isCurrentPlanFree = companyJBPackage.packageDetails.free;
    this.hasFreeExclusiveAccess = companyJBPackage.packageDetails.freeExclusive;
    this.currentPlanName = companyJBPackage.packageDetails.name + ' Plan';
    this.updateRemainingPostsCountText(companyJBPackage);
    const renewalDate = companyJBPackage.nextResetDate
      ? new Date(companyJBPackage.nextResetDate)
      : null;
    this.nextResetDate = renewalDate?.toDateString().substring(4);
    this.resetDateLabel =
      companyJBPackage.status == SFServiceStatus.ACTIVE
        ? 'Next renewal'
        : 'Expiration date';
    this.isLoading = false;
    this.isWithRecruitment = companyJBPackage.isWithRecruitment;
  }

  private updateRemainingPostsCountText(companyJBPackage: CompanyJBPackage) {
    this.totalPostsCount = companyJBPackage.packageDetails.postCount;
    this.remainingPostsCount = companyJBPackage.remainingPostsCount;
    if (this.totalPostsCount === null) {
      this.remainingPostsText = 'Unlimited job posts';
    } else {
      if (
        this.companyPackageCardLayout === CompanyPackageCardLayout.FULL_CARD
      ) {
        this.remainingPostsText =
          this.remainingPostsCount +
          ' out of ' +
          this.totalPostsCount +
          ' post' +
          (this.totalPostsCount > 1 ? 's' : '') +
          ' remaining';
      } else if (
        this.companyPackageCardLayout === CompanyPackageCardLayout.SINGLE_ROW
      ) {
        this.remainingPostsText =
          this.remainingPostsCount +
          ' job post' +
          (this.remainingPostsCount > 1 ? 's' : '') +
          ' left';
      }
    }
  }

  ngOnDestroy(): void {
    this.terminateSubs$.next(null);
    this.terminateSubs$.complete();
  }
}
