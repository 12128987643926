<ul class="margin-childs-horizontal-28">
  <li *ngFor="let link of headerData?.navLinks">
    <a
      *ngIf="!isExternalLink(link.url)"
      [routerLink]="link.url"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: link.url == '' || link.url == '/' }">
      <ng-container *ngTemplateOutlet="navItemTemplate; context: { $implicit: link }"></ng-container>
    </a>
    <a
      *ngIf="isExternalLink(link.url)"
      [href]="link.url"
      target="_blank">
      <ng-container *ngTemplateOutlet="navItemTemplate; context: { $implicit: link }"></ng-container>
    </a>
  </li>
</ul>

<ng-template
  let-link
  #navItemTemplate>
  <span>
    {{ link.name }}
  </span>
  <ng-container *ngIf="link.navLinks">
    <span
      class="menu-trigger"
      [matMenuTriggerFor]="morelinks"
      #trigger="matMenuTrigger">
    </span>
    <app-svg-icon
      iconSrc="/assets/icons/arrow-down.svg"
      class="arrow"
      width="16px" />
    <mat-menu #morelinks>
      <a
        *ngFor="let link of link.navLinks"
        mat-menu-item
        [routerLink]="link.url">
        {{ link.name }}
      </a>
    </mat-menu>
  </ng-container>
</ng-template>
