import { Component, Input, OnInit } from '@angular/core';
import { HeaderServices } from '../../header.service';
import { HeaderData } from './../../header-data';

@Component({
  selector: 'app-header-authenticated-navbar',
  templateUrl: './header-authenticated-navbar.component.html',
  styleUrls: ['./header-authenticated-navbar.component.scss'],
})
export class HeaderAuthenticatedNavbarComponent implements OnInit {
  @Input() headerData: HeaderData;
  @Input() currentUrl;

  constructor(private headerServices: HeaderServices) {}

  ngOnInit(): void {}

  isExternalLink(link: string) {
    return this.headerServices.isExternalLink(link);
  }
}
