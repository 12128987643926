import { Vertical } from '../../models/vertical/vertical';

export const verticalList: Vertical[] = [
  new Vertical({
    name: 'Development',
    url: 'hire-developers',
    jobName: 'Developers',
    imageUrl: '../assets/images/verticals/vertical-list/icons/development.svg',
    iconColor: 'var(--teal-500)',
    mostPopularJobName: 'Application Developer',
    skilledImg: '/assets/images/verticals/people/skilled-6',
  }),
  new Vertical({
    name: 'Design',
    url: 'hire-designers',
    jobName: 'Designers',
    imageUrl: '../assets/images/verticals/vertical-list/icons/design.svg',
    iconColor: 'var(--pink-500)',
    mostPopularJobName: 'Web Designer',
    skilledImg: '/assets/images/verticals/people/skilled-30',
  }),
  new Vertical({
    name: 'Test & QA',
    url: 'hire-test-engineers',
    jobName: 'Testers',
    imageUrl: '../assets/images/verticals/vertical-list/icons/test-&-qa.svg',
    iconColor: 'var(--red-500)',
    mostPopularJobName: 'Test Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-16',
  }),
  new Vertical({
    name: 'AI & Data Science',
    url: 'hire-artificial-intelligence',
    jobName: 'Artificial Intelligence Engineers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/ai-&-data-science.svg',
    iconColor: 'var(--violet-600)',
    mostPopularJobName: 'AI Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-3',
  }),
  new Vertical({
    name: 'Product Management',
    url: 'hire-product-managers',
    jobName: 'Product Managers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/product-management.svg',
    iconColor: 'var(--teal-500)',
    mostPopularJobName: 'Product Manager',
    skilledImg: '/assets/images/verticals/people/skilled-27',
  }),
  new Vertical({
    name: 'Project Management',
    url: 'hire-project-managers',
    jobName: 'Project Managers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/project-management.svg',
    iconColor: 'var(--red-500)',
    mostPopularJobName: 'Project Manager',
    skilledImg: '/assets/images/verticals/people/skilled-8',
  }),
  new Vertical({
    name: 'Business & Marketing',
    url: 'hire-business-marketing',
    jobName: 'Business & Marketing Experts',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/business-&-marketing.svg',
    iconColor: 'var(--amber-500)',
    mostPopularJobName: 'Marketing or Sales Expert',
    skilledImg: '/assets/images/verticals/people/skilled-24',
  }),
  new Vertical({
    name: 'Top Management',
    url: 'hire-top-managers-and-executives',
    jobName: 'Top Managers & Executives',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/top-management.svg',
    iconColor: 'var(--amber-500)',
    mostPopularJobName: 'Top Manager',
    skilledImg: '/assets/images/verticals/people/skilled-28',
  }),
  new Vertical({
    name: 'Customer Support',
    url: 'support-outsourcing',
    jobName: 'Customer Support',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/customer-support.svg',
    iconColor: 'var(--slate-600)',
    mostPopularJobName: 'Support Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-29',
  }),
  new Vertical({
    name: 'Networks',
    url: 'hire-network-engineers',
    jobName: 'Network Engineers',
    imageUrl: '../assets/images/verticals/vertical-list/icons/network.svg',
    iconColor: 'var(--fuchsia-500)',
    mostPopularJobName: 'Networks Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-9',
  }),
  new Vertical({
    name: 'IT & Operations',
    url: 'it-operations-outsourcing',
    jobName: 'IT & Operations Engineers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/it-&-operations.svg',
    iconColor: 'var(--red-500)',
    mostPopularJobName: 'IT Operations Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-7',
  }),
  new Vertical({
    name: 'Cloud',
    url: 'hire-cloud-experts',
    jobName: 'Cloud Experts',
    imageUrl: '../assets/images/verticals/vertical-list/icons/cloud.svg',
    iconColor: 'var(--sky-400)',
    mostPopularJobName: 'Cloud Architect',
    skilledImg: '/assets/images/verticals/people/skilled-23',
  }),
  new Vertical({
    name: 'Security',
    url: 'hire-security-experts',
    jobName: 'Security Experts',
    imageUrl: '../assets/images/verticals/vertical-list/icons/security.svg',
    iconColor: 'var(--violet-600)',
    mostPopularJobName: 'Security Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-4',
  }),
  new Vertical({
    name: 'Storage & Backup',
    url: 'hire-storage-backup-engineers',
    jobName: 'Storage & Backup Engineers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/storage-&-backup.svg',
    iconColor: 'var(--sky-400)',
    mostPopularJobName: 'Storage & Backup Administrator',
    skilledImg: '/assets/images/verticals/people/skilled-21',
  }),
  new Vertical({
    name: 'ERP',
    url: 'hire-erp-consultants',
    jobName: 'ERP Consultants',
    imageUrl: '../assets/images/verticals/vertical-list/icons/erp.svg',
    iconColor: 'var(--red-500)',
    mostPopularJobName: 'ERP Expert',
    skilledImg: '/assets/images/verticals/people/skilled-59',
  }),
  new Vertical({
    name: 'Embedded Engineering',
    url: 'hire-embedded-engineers',
    jobName: 'Embedded Engineers',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/embedded-engineering.svg',
    iconColor: 'var(--teal-500)',
    mostPopularJobName: 'Embedded Software Engineer',
    skilledImg: '/assets/images/verticals/people/skilled-32',
  }),
];

export const duplicateVerticalList: Vertical[] = [
  new Vertical({
    name: 'Test & QA',
    url: 'hire-quality-assurance',
    jobName: 'Quality Assurance Engineers',
    imageUrl: '../assets/images/verticals/vertical-list/icons/test-&-qa.svg',
    mostPopularJobName: 'Test Engineer',
    iconColor: 'var(--red-500)',
    mainVerticalNameUrl: 'hire-test-engineers',
  }),
  new Vertical({
    name: 'AI & Data Science',
    url: 'hire-data-scientists',
    jobName: 'Data Scientists',
    imageUrl:
      '../assets/images/verticals/vertical-list/icons/ai-&-data-science.svg',
    iconColor: 'var(--violet-600)',
    mostPopularJobName: 'AI Engineer',
    mainVerticalNameUrl: 'hire-artificial-intelligence',
  }),
];

function verticalIndex(verticalList, urlPath) {
  return verticalList.map((vertical) => vertical.url).indexOf(urlPath);
}

export { verticalIndex as getVerticalIndex };
