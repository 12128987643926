import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { CometChatService } from 'src/services/third-party/comet-chat.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ChatGuard {
  constructor(
    private cometChatService: CometChatService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.cometChatService.getUserHasChats() !== undefined)
      return this.cometChatService.getUserHasChats();
    else
      return this.authenticationService.getUserInfo().pipe(
        first(),
        map((userInfo) => userInfo.hasChats),
      );
  }
}
