import { BaseEntity } from './../abstract/baseEntity';
import { Team } from './../team/team';

export class EmailInvitedToTeam extends BaseEntity {
  team: Team;
  email: string;

  public constructor(email: string, team: Team = null) {
    super();
    this.email = email;
    this.team = team;
  }
}

export class EmailInvitedToTeamUtils {
  public static createListOfEntitiesFromListOfEmails(emails: string[]) {
    return emails.map((email: string) => new EmailInvitedToTeam(email));
  }
}
