<mat-accordion class="nav-bar-expansion">
  <!--
  <ng-container *ngTemplateOutlet="linkTemplate;context:{
      name:'Home',
      link:'/',
      isLinkExact:true,
      iconSrc:'/assets/icons/home.svg'
      }">
  </ng-container> -->
  <ng-container
    *ngTemplateOutlet="
      linkTemplate;
      context: {
        name: 'Home',
        link: '/client/dashboard',
        isLinkExact: true,
        iconSrc: '/assets/icons/home.svg',
      }
    " />

  <ng-container
    *ngTemplateOutlet="
      linkTemplate;
      context: {
        name: 'Job posts',
        link: '/client/dashboard/job-posts',
        isLinkExact: false,
        iconSrc: '/assets/icons/posts.svg',
      }
    " />

  <ng-container *ngIf="showPeople">
    <ng-container
      *ngTemplateOutlet="
        linkTemplate;
        context: {
          name: 'People',
          link: '/client/dashboard/people',
          isLinkExact: true,
          iconSrc: '/assets/icons/people.svg',
        }
      " />
  </ng-container>

  <ng-container
    *ngTemplateOutlet="
      linkTemplate;
      context: {
        name: 'Assessments',
        link: '/client/dashboard/assessments',
        isLinkExact: false,
        label: 'Beta',
        labelTemplate: aiIconTemplate,
        iconSrc: '/assets/icons/assessments.svg',
      }
    " />

  <ng-template #aiIconTemplate>
    <app-ai-icon class="mg-l-4" />
  </ng-template>

  <ng-container
    *ngTemplateOutlet="
      linkTemplate;
      context: {
        name: 'Salaries calculator',
        link: '/find-salaries',
        isLinkExact: false,
        iconSrc: '/assets/icons/salaries.svg',
      }
    " />

  <ng-container
    *ngTemplateOutlet="
      linkTemplate;
      context: {
        name: 'Analytics',
        link: '/client/dashboard/analytics',
        isLinkExact: true,
        iconSrc: '/assets/icons/analytics.svg',
      }
    " />

  <ng-container *ngIf="companyId$ | async as companyId">
    <ng-container
      *ngTemplateOutlet="
        linkTemplate;
        context: {
          name: 'Careers page',
          link: '/client/company/profile/' + companyId + '/careers/preview',
          isLinkExact: false,
          iconSrc: '/assets/icons/world.svg',
        }
      " />

    <ng-container
      *ngTemplateOutlet="
        linkTemplate;
        context: {
          name: 'Company settings',
          link: '/client/company/profile/' + companyId + '/information',
          isLinkExact: false,
          iconSrc: '/assets/icons/settings.svg',
        }
      " />
  </ng-container>

  <ng-template
    #linkTemplate
    let-name="name"
    let-link="link"
    let-isLinkExact="isLinkExact"
    let-iconSrc="iconSrc"
    let-label="label"
    let-labelTemplate="labelTemplate">
    <mat-expansion-panel class="mat-expansion-parent no-indicator">
      <mat-expansion-panel-header
        [collapsedHeight]="''"
        [expandedHeight]="''">
        <a
          [routerLink]="link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: isLinkExact }"
          (isActiveChange)="isActive.var = $event"
          class="d-flex align-items-center cursor-pointer"
          #isActive="var"
          [var]="false">
          <app-svg-icon
            [color]="isActive.var ? 'var(--grey-800)' : null"
            [iconSrc]="iconSrc"
            width="20px" />
          <div
            class="mg-l-16 f-14 flex-shrink-0"
            style="line-height: 16px"
            [class]="isActive.var ? 'f-grey-800' : 'f-neutral-500'"
            (click)="handleClick()">
            {{ name }}
          </div>
          <div
            *ngIf="label"
            class="mg-l-18 f-10 f-white bg-amber-500 pd-6 br-6 d-flex align-items-center flex-shrink-0">
            {{ label }}
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate" />
        </a>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </ng-template>
</mat-accordion>
<div class="fixed-navs">
  <app-header-company-package-details
    *hideOn="'md'"
    [openDetailsPopup]="true"
    (goPremiumClicked)="handleClick()" />
  <app-company-card
    class="shrinkable"
    id="tour-step-company-3" />
  <!-- <a class="button-link-wrapper" (click)="sidenav.toggle()" routerLink="/client/post-your-job">
    <button mat-button class='btn-bk-blue-600'>
      <app-svg-icon height="16px" iconSrc="assets/icons/add.svg"/>
      <span class='f-white'>Add New Job Post</span>
    </button>
  </a>
  <a class="button-link-wrapper" (click)="openContactUs()">
    <button mat-button class="btn-bk-transparent">
      <span class="f-neutral-500">
        Contact Us
      </span>
    </button>
  </a> -->
</div>
