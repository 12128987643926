import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsBtnComponent } from './contact-us-btn.component';

@NgModule({
  declarations: [ContactUsBtnComponent],
  imports: [CommonModule],
  exports: [ContactUsBtnComponent],
})
export class ContactUsBtnModule {}
