<a
  (click)="sidenav.toggle(); routeToLink('/skilled-people/home/my-feeds')"
  routerLinkActive="active-link"
  href="/skilled-people/home/my-feeds"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="Skilled People Dashboard" />
  My Talent Dashboard
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/skilled-people/building-profile')"
  href="/skilled-people/building-profile"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/Group 624.svg"
    alt="Skilled People Dashboard" />
  My Talent Profile
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/skilled-people/home/my-tests')"
  routerLinkActive="active-link"
  href="/skilled-people/home/my-tests"
  style="cursor: pointer"
  mat-list-item>
  <img
    class="nav-icon"
    src="../../../assets/icons/my-tests.svg"
    alt="Skilled People Tests" />
  My Talent Tests
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/skilled-people/payments/overview')"
  routerLinkActive="active-link"
  href="/skilled-people/payments/overview"
  style="cursor: pointer"
  mat-list-item>
  <img
    class="nav-icon"
    src="../../../assets/icons/Payment details_ic.svg"
    alt="Talent payment details for admin" />
  My Talent Payment Details
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/client/dashboard')"
  routerLinkActive="active-link"
  href="/client/dashboard"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="../../../assets/icons/my-teams.svg"
    alt="Client dashboard for admin" />
  My Client Dashboard
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/client/profile')"
  href="/client/my-account/profile"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/Group 624.svg"
    alt="Client Profile for admin" />
  My Client Profile
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/admin/job-posts')"
  href="/admin/job-posts"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="Job posts for admin" />
  My Jobs
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/admin/external-jobs')"
  href="/admin/external-jobs"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="External Job posts for admin" />
  My External Jobs
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/find-salaries')"
  href="/find-salaries"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="Job posts for admin" />
  Find Salaries
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/services')"
  href="/admin/services"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="Services for admin" />
  My Services
</a>

<a
  (click)="sidenav.toggle(); routeToLink('/admin/scrapping')"
  href="/admin/scrapping"
  routerLinkActive="active-link"
  style="cursor: pointer"
  mat-list-item>
  <img
    src="/assets/icons/sidebar-navigation/feed.svg"
    alt="Scrapping for admin" />
  Scrapping
</a>
