import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CometChat } from '@cometchat-pro/chat';
import { environment } from 'src/environments/environment';
import { SfBrowserUtilities } from 'src/services/sf-browser-utilities.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { CometChatService } from './comet-chat.service';

//Parameters used at cometchat services
const appID = environment.cometchatConfig.appId;
const region = environment.cometchatConfig.region;

@Injectable({
  providedIn: 'root',
})
export class CometChatBrowserService extends CometChatService {
  CometChatLibrary = CometChat;

  constructor(
    private _authenticationService: AuthenticationService,
    private _titleService: Title,
    public _http: HttpClient,
    private _injector: Injector,
    private _sfBrowserUtilities: SfBrowserUtilities,
  ) {
    super(
      _authenticationService,
      _titleService,
      _http,
      _injector,
      _sfBrowserUtilities,
    );
  }
}
