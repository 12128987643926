import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, CompanyStatus } from 'src/models/company/company';
import { CompanyService } from 'src/models/company/company.service';

interface CompanyIssue {
  cta: string;
  title: string;
  description: string;
  ctaUrl: string;
}

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent implements OnInit {
  company: Company;
  currentUserCanEditCompany: boolean;
  terminateSubs$: Subject<any> = new Subject();

  ctaText: string;
  ctaUrl: string;
  statusDescription: string;
  borderColor: string;
  companyLogoUrl: string;
  companyName: string;
  completePercentage: number;
  companyEditUrl: string;
  packageName: string;
  packageTooltip: string;
  isCompanyPackageFree: boolean;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.companyService
      .currentUserCompanyCached()
      .pipe(takeUntil(this.terminateSubs$))
      .subscribe((company) => {
        this.company = company;
        const companyPackage = this.company?.jbPackage?.packageDetails;
        this.packageName = companyPackage?.name;
        if (!companyPackage || companyPackage.free)
          this.packageTooltip = "You're on the Free plan.";
        else this.packageTooltip = "You're on the Premium plan.";
        this.isCompanyPackageFree = companyPackage?.free;
        this.setCompanyAttributes();
        this.cdr.detectChanges();
      });
  }

  setCompanyAttributes() {
    this.companyName = this.company ? this.company.name : 'Company name';
    this.companyLogoUrl = this.company
      ? this.company.logoUrl
      : 'assets/icons/logo-text.png';
    this.completePercentage = this.company?.completePercentage;
    this.companyEditUrl = '/client/company/edit/' + this.company?.id;
    const status = this.company ? this.company.status : null;
    switch (status) {
      case CompanyStatus.INCOMPLETE:
        this.borderColor = 'var(--amber-500)';
        this.statusDescription = 'Your company record is not complete yet.';
        this.ctaText = 'Complete company';
        this.ctaUrl = this.companyEditUrl;
        break;
      case null:
        this.borderColor = 'var(--amber-500)';
        this.statusDescription = 'Your company is not created yet.';
        this.ctaText = 'Create company';
        this.ctaUrl = 'client/company/';
        break;
      case CompanyStatus.EMAIL_MISSING:
        this.borderColor = 'var(--amber-500)';
        this.statusDescription =
          "You need to verify your company by adding your company's email";
        this.ctaText = 'Verify company';
        this.ctaUrl = `/client/company/profile/${this.company.id}/verify`;
        break;
      case CompanyStatus.IN_REVIEW:
        this.borderColor = 'var(--blue-600)';
        this.statusDescription =
          'Your company record or new changes are in review, it will be updated soon.';
        this.ctaText = 'Edit';
        this.ctaUrl = this.companyEditUrl;
        break;
      case CompanyStatus.UNVERIFIED:
        this.borderColor = 'var(--red-500)';
        this.statusDescription = 'Your company is unverified';
        this.ctaText = 'Update company';
        this.ctaUrl = this.companyEditUrl;
        break;
      case CompanyStatus.ACTIVE:
        this.borderColor = 'var(--grey-300)';
        this.statusDescription =
          'Add verification badge to attract best talents.';
        this.ctaText = null;
        break;
      case CompanyStatus.SF_VERIFIED:
        this.borderColor = 'var(--grey-300)';
        this.ctaText = null;
        break;
    }
  }

  ctaClick(url: string) {
    this.router.navigateByUrl(url);
  }

  goToPricingPage() {
    if (this.isCompanyPackageFree) this.router.navigateByUrl('/client/pricing');
  }

  goToCompanyPage() {
    this.router.navigateByUrl('/client/company/');
  }

  openKnowWhyOverlay() {}

  ngOnDestroy(): void {
    this.terminateSubs$.next(null);
    this.terminateSubs$.complete();
  }
}
