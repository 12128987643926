<ng-template #hireContainerOverlay>
  <div
    class="hire-overlay-container margin-childs-vertical-20"
    [ngClass]="{ 'with-shadow': !darkOverlay }"
    @fadeInOut>
    <div class="dashboard-card team">
      <div class="f-24 fw-500">Hire Remote Teams</div>
      <app-arrow-button
        (click)="toggleHireOverlay(); sidenav?.toggle()"
        routerLink="/client/build-your-team"
        [queryParams]="{ jobTitle: jobTitle }">
        Create a Team
      </app-arrow-button>
      <div class="dashboard-card job">
        <div class="f-24 fw-500">Post Job Ads</div>
        <app-arrow-button
          (click)="toggleHireOverlay(); sidenav?.toggle()"
          routerLink="/client/post-your-job"
          [queryParams]="{ jobTitle: jobTitle }">
          Create a Job Post
        </app-arrow-button>
      </div>
    </div>
  </div>
</ng-template>
