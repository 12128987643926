<div
  class="premium-btn-wrapper"
  routerLink="/client/pricing">
  <button
    mat-button
    class="btn-bk-blue-gradient f-white btn-small">
    <app-svg-icon
      iconSrc="assets/icons/upgrade.svg"
      width="20px"
      color="white" />
    <span> Go Premium </span>
  </button>
</div>
