import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { ApiError, ExceptionName } from 'src/models/apiError';
import { GlobalConstants } from 'src/models/globalConstants';
import { AlertFactoryService } from '../services/alertFactory.service';
import { SFErrorHandlerService } from '../services/error-handling.service';
import { SfBrowserUtilities } from '../services/sf-browser-utilities.service';
import { HeapAnalyticsService } from '../services/third-party/heap/heap-analytics.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // this class handles the following errors:
  // NO Network
  // internal server error
  // Bad request

  readonly NO_CONNECTION_TITLE = 'No network connection';
  readonly INTERNAL_SERVER_ERROR_TITLE = 'Unexpected Issue';

  readonly NO_CONNECTION_MSG = 'Please check your network connection';
  readonly INTERNAL_SERVER_ERROR_MSG = 'Looks like something is wrong';

  readonly MAX_RETRY_ATTEMPTS = 5;
  readonly RETRY_DELAY = 4000;
  constructor(
    private alertFactory: AlertFactoryService,
    private errorHandler: SFErrorHandlerService,
    private localStorage: SfBrowserUtilities,
    private heap: HeapAnalyticsService,
  ) {}

  isOnline() {
    // return window.navigator.onLine;
    return true;
  }

  createNoConnectionApiError() {
    new ApiError(
      0,
      ExceptionName.NO_CONNECTION,
      'No connection',
      'your network is not working',
    );
  }

  handleUnkownErrorRetry(errorObs: Observable<any>, handleRetry: boolean) {
    return errorObs.pipe(
      mergeMap((error: HttpErrorResponse, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          error.status == 0 &&
          retryAttempt < this.MAX_RETRY_ATTEMPTS &&
          handleRetry
        ) {
          return timer(this.RETRY_DELAY);
        } else return throwError(error);
      }),
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let handleOtherErrors =
      request.headers.get(GlobalConstants.HANDLE_ERROR_HEADER) == 'true';
    let handleRetry =
      request.headers.get(GlobalConstants.HANDLE_RETRY_HEADER) == 'true';

    // this header is to pass data to the error interceptor
    // and we don't want to send if
    if (handleOtherErrors)
      request = request.clone({
        headers: request.headers.delete(GlobalConstants.HANDLE_ERROR_HEADER),
      });

    if (handleRetry)
      request = request.clone({
        headers: request.headers.delete(GlobalConstants.HANDLE_RETRY_HEADER),
      });

    // handle network error
    if (this.localStorage.isBrowser && !this.isOnline()) {
      this.alertFactory.openErrorSnackBar({
        title: this.NO_CONNECTION_TITLE,
        message: this.NO_CONNECTION_MSG,
      });
      return throwError(this.createNoConnectionApiError());
    }

    return next.handle(request).pipe(
      retryWhen((error: Observable<any>) =>
        this.handleUnkownErrorRetry(error, handleRetry),
      ),
      catchError((error: HttpErrorResponse) => {
        // handle error in response
        let apiError: ApiError = error.error;
        let requestModified = { ...request };
        if (
          !request.url.endsWith('/login') &&
          !request.url.endsWith('/customer-signup') &&
          !request.url.endsWith('/user-signup') &&
          !request.url.endsWith('/change-password')
        )
          requestModified.body = JSON.stringify(request.body);
        this.heap.track('API Error', {
          ...error,
          ...requestModified,
          ...apiError,
        });
        // let displayError:boolean = handleOtherErrors && apiError.showToUser
        if (handleOtherErrors && this.localStorage.isBrowser)
          this.errorHandler.handleError(error);

        return throwError(error);
      }),
    );
  }
}
