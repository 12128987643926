import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JobVacancyFilterService } from '../../find-work/job-vacancy-filter.service';

@Component({
  selector: 'app-job-vacancy-text-search',
  templateUrl: './job-vacancy-text-search.component.html',
  styleUrls: ['./job-vacancy-text-search.component.scss'],
})
export class JobVacancyTextSearchComponent implements OnInit {
  @Input() searchControl = new UntypedFormControl();
  @Input() alwaysMini = false;
  @Input() clearAfterSearch = false;
  @Input() searchAfterClear = false;
  @Input() searchBtnColor = 'dark';
  @Input() hideSearchIconInDesktop = true;
  @Input() searchQuery: String = '';
  @Input() focusBorderMode: 'skilled' | 'customer' | 'transparent';
  @Input() leadingIconSrc: string;

  @Output() doSearch: EventEmitter<string> = new EventEmitter();
  @Output() inputFieldFocusEvent = new EventEmitter<void>();

  constructor(
    private jobVacancyFilterService: JobVacancyFilterService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  doSearchFn(searchQuery: string) {
    if (this.inFindWorkPage())
      this.jobVacancyFilterService.searchQueryChanged(searchQuery);
    else
      this.router.navigate(['/skilled-people/find-work'], {
        queryParams: { searchQuery: searchQuery },
      });
    this.doSearch.emit(searchQuery);
    if (this.clearAfterSearch)
      this.searchControl.setValue('', { emitEvent: false });
  }

  handleClear() {
    if (this.searchAfterClear)
      this.jobVacancyFilterService.searchQueryChanged('');
  }

  inFindWorkPage() {
    let url = this.router.url.split('#')[0];
    url = url.split('?')[0];
    return url.endsWith('find-work') || url.endsWith('careers');
  }

  onInputFieldFocusEvent() {
    this.inputFieldFocusEvent.emit();
  }
}
