import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Currency } from 'src/models/currency/currency';
import { Team } from 'src/models/team/team';
import { ConnectionService } from 'src/services/connection.service';
import { ResourceService } from 'src/services/resource.service';
import { HeapAnalyticsService } from 'src/services/third-party/heap/heap-analytics.service';
import { UserLocalStorageService } from 'src/services/user-local-storage.service';
import { Group } from '../group/group';
import { GroupService } from '../group/group.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends ResourceService<Team> {
  constructor(
    private _connection: ConnectionService,
    private userPrefrencesService: UserLocalStorageService,
    private groupApiService: GroupService,
    private heapAnalyticsService: HeapAnalyticsService,
  ) {
    super(_connection, 'team/', Team);
  }

  saveTeam(team: Team, teamFG?: UntypedFormGroup) {
    let reqObs = this.getConnection()
      .post(this.getEndPoint() + 'save-team', this.prepareTeamForBackEnd(team))
      .pipe(
        tap((team: Team) => team.groups.map(this.mergeUnMatched.bind(this))),
      );
    if (teamFG)
      return reqObs.pipe(
        tap((teamWithData: Team) =>
          this.updateTeamPriceData(teamFG, teamWithData),
        ),
        tap((teamWithData: Team) =>
          this.updateTeamAndGroupIds(teamFG, teamWithData),
        ),
      );

    return reqObs;
  }

  bookTeam(team: Team) {
    return this.getConnection().post(
      this.getEndPoint() + 'book-team',
      this.prepareTeamForBackEnd(team),
      { handleLoading: true, loadingMsg: 'Requesting your Team...' },
    );
  }

  bookSavedTeam(teamId: number) {
    let headers = {};
    return this.getConnection().post(
      this.getEndPoint() + 'book-saved-team?teamId=' + teamId,
      {},
    );
  }

  getSavedOrDraftTeam(
    teamId: number,
    currency: Currency,
    handleError: boolean = true,
  ) {
    return this.getConnection()
      .get(
        this.getEndPoint() +
          'get-saved-or-draft-team?teamId=' +
          teamId +
          '&currencyIsoCode=' +
          currency.isoCode,
        {
          handleError: handleError,
          handleLoading: true,
          loadingMsg: 'Loading team',
        },
      )
      .pipe(
        tap((team: Team) => team.groups.map(this.mergeUnMatched.bind(this))),
      );
  }

  getAllUserTeams() {
    return this.getConnection()
      .get(this.getEndPoint() + 'get-all-teams')
      .pipe(
        tap((teams: Team[]) =>
          teams.forEach((team) =>
            team.groups.map(this.mergeUnMatched.bind(this)),
          ),
        ),
      );
  }

  getAllTemplateTeams() {
    const currencyMetaData =
      this.userPrefrencesService.getItem('currencyMetaData');
    const userCurrency = currencyMetaData
      ? currencyMetaData.currency.isoCode
      : Currency.getUsd().isoCode;
    return this.connection.get(
      this.getEndPoint() + 'get-all-templates?currencyIsoCode=' + userCurrency,
    );
  }

  renameTeam(teamId: number, name: string) {
    return this.getConnection()
      .post(this.getEndPoint() + 'rename?teamId=' + teamId, name)
      .pipe(
        tap((team: Team) => team.groups.map(this.mergeUnMatched.bind(this))),
      );
  }

  deleteTeam(teamId: number): Observable<boolean> {
    return this.getConnection().post(
      this.getEndPoint() + 'delete?teamId=' + teamId,
      {},
    );
  }

  addUsersToTeam(
    teamId: number,
    usersEmails: string[],
    message: string,
  ): Observable<Team> {
    const body = {
      teamId,
      emails: JSON.stringify(usersEmails),
      message: JSON.stringify(message),
    };
    return this.getConnection().post(
      this.getEndPoint() + 'add-users-to-team',
      body,
    );
  }

  removeUserFromTeam(teamId: number, email: string): Observable<void> {
    const body = {
      teamId,
      email: JSON.stringify(email),
    };
    return this.getConnection().post(
      this.getEndPoint() + 'remove-user-from-team',
      body,
    );
  }

  calcTeamPriceAndUpdateForm(
    teamFormGroup: UntypedFormGroup,
    handleError: boolean = true,
  ) {
    let teamWithValidGroups = this.getTeamWithValidGroups(teamFormGroup);
    return this.calcTeamPrice(teamWithValidGroups).pipe(
      tap((teamWithData: Team) =>
        this.updateTeamPriceData(teamFormGroup, teamWithData),
      ),
      tap((team) => this.heapPriceCalcEvent(teamWithValidGroups, team)),
    );
  }

  calcTeamPrice(team: Team, handleError: boolean = true) {
    let backendTeam = this.prepareTeamForBackEnd(team);
    return this.getConnection()
      .post(this.getEndPoint() + 'calc-price', backendTeam, {
        handleError: handleError,
      })
      .pipe(
        tap((team: Team) => team.groups.map(this.mergeUnMatched.bind(this))),
      );
  }

  heapPriceCalcEvent(teamBeforeCalc, CalculatedTeam) {
    const groupIndexWithoutPrice = teamBeforeCalc.groups.map(
      (group) => !group.totalPrice,
    );
    const modifiedGroups = CalculatedTeam.groups.map((group, index) => {
      if (teamBeforeCalc.groups[index].totalPrice == group.totalPrice)
        return null;
      else if (groupIndexWithoutPrice[index])
        // first price for group
        return { ...group, firstPrice: true };
      return group; //modified group price
    });
    for (let i = 0; i < modifiedGroups.length; i++) {
      const modifiedGroup = modifiedGroups[i];
      if (modifiedGroup) {
        this.heapAnalyticsService.track('Group Price Calculated', {
          name: modifiedGroup.name,
          job: modifiedGroup.job.name,
          skills: modifiedGroup.skills.map((skill) => skill.name).join(' | '),
          languages: modifiedGroup.languages
            .map((language) => language.name)
            .join(' | '),
          experienceLevel: modifiedGroup.experienceLevel,
          totalPrice: modifiedGroup.totalPrice,
          totalPriceBeforeDiscount: modifiedGroup.totalPriceBeforeDiscount,
          billingCycle: modifiedGroup.billingCycle,
        });
      }
    }
  }

  draftTeam(teamFormGroup: UntypedFormGroup, handleError: boolean = true) {
    let teamWithValidGroups = this.getTeamWithValidGroups(teamFormGroup);
    let backendTeam = this.prepareTeamForBackEnd(teamWithValidGroups);
    backendTeam = this.removeTeamAndGroupIds(backendTeam); // no updating for draft teams

    return this.getConnection()
      .post(this.getEndPoint() + 'draft-team', backendTeam, {
        handleError: handleError,
      })
      .pipe(
        tap((team: Team) => team.groups.map(this.mergeUnMatched.bind(this))),
      );
  }
  updateTeamPriceData(teamFormGroup: UntypedFormGroup, teamWithData: Team) {
    let groupsFormArray = <UntypedFormArray>teamFormGroup.get('groups');
    teamFormGroup.get('totalPriceRange').setValue(teamWithData.totalPriceRange);
    let validFormGroups: UntypedFormGroup[] = <UntypedFormGroup[]>(
      groupsFormArray.controls.filter(
        (groupformGroup: UntypedFormGroup) => groupformGroup.valid,
      )
    );
    for (let gIndex = 0; gIndex < validFormGroups.length; gIndex++) {
      let groupFormGroup = validFormGroups[gIndex];
      if (groupFormGroup.invalid)
        // group wasn't set for price calc so skip it
        continue;
      let groupWithPrice = teamWithData.groups[gIndex];
      this.groupApiService.updateGroupFGPricing(groupFormGroup, groupWithPrice);
    }
  }

  updateTeamAndGroupIds(teamFormGroup: UntypedFormGroup, teamWithData: Team) {
    let groupsFormArray = <UntypedFormArray>teamFormGroup.get('groups');
    let groupControls = groupsFormArray.controls;
    teamFormGroup.get('id').setValue(teamWithData.id);
    for (let gIndex = 0; gIndex < groupControls.length; gIndex++) {
      let groupFormGroup = groupControls[gIndex];
      let groupWithPrice = teamWithData.groups[gIndex];
      groupFormGroup.get('id').setValue(groupWithPrice.id);
    }
  }

  getTeamWithValidGroups(teamFormGroup: UntypedFormGroup): Team {
    let team: Team = teamFormGroup.value;
    let groupsFormArray = <UntypedFormArray>teamFormGroup.get('groups');
    let validFormGroups: Group[] = <Group[]>(
      groupsFormArray.controls
        .filter((groupformGroup: UntypedFormGroup) => groupformGroup.valid)
        .map((groupformGroup: UntypedFormGroup) => groupformGroup.value)
    );

    team.groups = validFormGroups;
    return team;
  }

  removeTeamAndGroupIds(team: Team) {
    team.id = null;
    team.groups = team.groups.map((group) => {
      group.id = null;
      return group;
    });
    return team;
  }

  shareTeamToMultipleEmails(entity, recaptchaToken, teamLink, teamId) {
    const header = {
      recaptcha: recaptchaToken,
    };
    teamLink = encodeURIComponent(teamLink);
    return this.connection.post(
      this.endpoint +
        'share-team-multiple-emails?teamLink=' +
        teamLink +
        '&teamId=' +
        teamId,
      entity,
      { headers: header, handleLoading: true, loadingMsg: 'Sending' },
    );
  }

  getbyId(teamId: number) {
    return this.getConnection().get(this.getEndPoint() + 'get?id=' + teamId, {
      handleLoading: true,
      loadingMsg: 'Loading team',
    });
  }

  mergeUnMatched(group: Group) {
    return this.groupApiService.mergeUnMatched(group);
  }
  cleanTeam(team: Team): Team {
    delete team['promoCode'];
    delete team['usedPromo'];
    for (let i = 0; i < team.groups.length; i++) {
      this.groupApiService.cleanGroup(team.groups[i]);
    }

    return team;
  }

  prepareTeamForBackEnd(team: Team): Team {
    team = JSON.parse(JSON.stringify(team));
    return this.cleanTeam(team);
  }
}
