import { Component, Input, OnInit } from '@angular/core';
import { HelpChatBotService } from 'src/services/help-chat-bot.service';

@Component({
  selector: 'app-contact-us-btn',
  templateUrl: './contact-us-btn.component.html',
  styleUrls: ['./contact-us-btn.component.scss'],
})
export class ContactUsBtnComponent implements OnInit {
  @Input() imgSrc: string = 'assets/icons/contact-us-avatar1.svg';

  constructor(private helpChatBotService: HelpChatBotService) {}

  ngOnInit(): void {}

  openContactUs() {
    this.helpChatBotService.openChat();
  }
}
