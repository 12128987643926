import { Component, Input } from '@angular/core';
import { Company, CompanyStatus } from 'src/models/company/company';
import { FunctionalUtils } from 'src/utilities/functionalUtils';

@Component({
  selector: 'app-company-status',
  templateUrl: './company-status.component.html',
  styleUrls: ['./company-status.component.scss'],
})
export class CompanyStatusComponent {
  @Input() company: Company;

  statusText: string;
  color: string;
  iconName: string;
  tooltip: string;

  constructor() {}

  ngOnChanges() {
    this.statusText = this.company?.status
      ? FunctionalUtils.toTitleCase(this.company.status.replace('_', ' '))
      : 'Not created';
    switch (this.company?.status) {
      case CompanyStatus.INCOMPLETE:
      case undefined:
        this.iconName = 'alert-triangle';
        this.color = 'yellow';
        break;
      case CompanyStatus.EMAIL_MISSING:
        this.iconName = 'alert-triangle';
        this.color = 'yellow';
        this.statusText = 'Unverified';
        break;
      case CompanyStatus.IN_REVIEW:
        this.iconName = 'history';
        this.color = 'blue';
        break;
      case CompanyStatus.UNVERIFIED:
        this.iconName = 'alert-triangle';
        this.color = 'red';
        break;
      case CompanyStatus.ACTIVE:
        this.iconName = null;
        this.color = 'blue';
        break;
      case CompanyStatus.SF_VERIFIED:
        this.iconName = 'check';
        this.color = 'green';
        this.statusText = 'Verified';
        break;
    }
    this.tooltip = 'Your company is ' + this.statusText;
  }
}
