<div
  class="feed-btn-container"
  (click)="toggleFeed()"
  tabindex="0"
  #feedContainer>
  <div
    class="notification-icon"
    [class]="feedOverlayRef && feedOverlayRef.hasAttached() ? 'active' : ''">
    <app-svg-icon
      iconSrc="../../assets/icons/bell.svg"
      width="19.2px"
      height="19.px"
      [matBadge]="unReadCount > 9 ? '9+' : unReadCount"
      [matBadgeHidden]="unReadCount === 0"
      matBadgeSize="small"
      [class]="feedOverlayRef && feedOverlayRef.hasAttached() ? 'active' : ''" />
  </div>
</div>

<ng-template #feedContainerOverlay>
  <div
    class="feed-container"
    @fadeInOut>
    @if (loadingFeed()) {
      <div class="d-flex justify-content-center pd-20"> <app-loading-mini> </app-loading-mini> </div>
    } @else {
      <div class="notification-header">
        <span>Notifications</span>
      </div>
      <div
        class="notifications-body"
        [ngClass]="{ empty: appUserNotifications?.length == 0 }">
        <div
          *ngIf="appUserNotifications?.length == 0"
          class="not-found">
          <div>
            <app-svg-icon
              iconSrc="assets/icons/nothing-found.svg"
              width="70px"
              color="var(--grey-400)" />
            <span class="row f-14">Currently no feeds to show</span>
          </div>
        </div>
        <ng-container *ngFor="let appUserNotification of appUserNotifications">
          <ng-container
            *ngIf="appUserNotification.notification.external_url; then externalURLTemplate; else internalURLTemplate">
          </ng-container>
          <ng-template #externalURLTemplate>
            <a
              class="notification-container"
              [ngClass]="{ read: appUserNotification.viewed, 'no-url': !appUserNotification.notification.url }"
              [@simpleFadeAnimation]="'in'"
              [target]="appUserNotification.notification.external_url ? '_blank' : null"
              [href]="appUserNotification.notification.url">
              <ng-container *ngTemplateOutlet="notificationCard"></ng-container>
            </a>
          </ng-template>
          <ng-template #internalURLTemplate>
            <a
              class="notification-container"
              [ngClass]="{ read: appUserNotification.viewed, 'no-url': !appUserNotification.notification.url }"
              [@simpleFadeAnimation]="'in'"
              [href]="appUserNotification.notification.url"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                navigate(appUserNotification.notification.url);
                toggleFeed()
              ">
              <ng-container *ngTemplateOutlet="notificationCard"></ng-container>
            </a>
          </ng-template>

          <ng-template #notificationCard>
            <div class="row">
              <div
                class="notification-image"
                *ngIf="appUserNotification.img">
                <app-image-round
                  width="50px"
                  [imageSrc]="appUserNotification.img">
                </app-image-round>
              </div>
              <div class="notification-content">
                <div class="notification-message">
                  <p
                    class="f-14"
                    [innerHTML]="appUserNotification.notification.message"></p>
                  <app-svg-icon
                    class="delete-feed"
                    iconSrc="assets/icons/close2.svg"
                    width="18px"
                    alt="delete icon"
                    color="var(--grey-400)"
                    hoverColor="var(--neutral-700)"
                    (click)="$event.preventDefault(); $event.stopPropagation(); deleteFeedEntry(appUserNotification)" />
                </div>
              </div>
            </div>
            <div class="notification-footer">
              <div
                class="notification-type"
                [ngClass]="appUserNotification.class">
                <img
                  *ngIf="appUserNotification.class === 'bonus'"
                  [src]="appUserNotification.iconPath"
                  alt="bonus" />
                <span class="f-12"> {{ appUserNotification.notification.type }} </span>
              </div>
              <div class="notification-date f-12">
                {{
                  appUserNotification.formatDate
                    ? (appUserNotification.createDateTime | date: 'EEE, MMM d, h:mmaaa')
                    : appUserNotification.createDateTime
                }}
              </div>
            </div>
            <hr />
          </ng-template>
        </ng-container>
      </div>
    }
  </div>
</ng-template>
