import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaComponent } from './recaptcha.component';
import { RecaptchaModule as NGRecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [RecaptchaComponent],
  imports: [CommonModule, NGRecaptchaModule],
  exports: [RecaptchaComponent],
})
export class RecaptchaModule {}
