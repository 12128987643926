import { Component, Input } from '@angular/core';
import { UtilitiesService } from '../../../../../services/utilities.service';
import { HeaderServices } from '../../header.service';
import { HeaderData } from './../../header-data';
import {
  defaultHeaderNavLinks,
  jobHeaderNavLinks,
  skilledLandingHeaderNavLinks,
} from './header-unauthenticated-data';

@Component({
  selector: 'app-header-unauthenticated-navbar',
  templateUrl: './header-unauthenticated-navbar.component.html',
  styleUrls: ['./header-unauthenticated-navbar.component.scss'],
})
export class HeaderUnauthenticatedNavbarComponent {
  @Input() currentUrl: string;

  headerData: HeaderData;

  constructor(
    public headerServices: HeaderServices,
    private utilities: UtilitiesService,
  ) {}

  ngOnChanges(): void {
    if (this.currentUrl)
      this.changeHeaderDataBasedOnCurrentUrl(this.currentUrl);
  }

  changeHeaderDataBasedOnCurrentUrl(url: string) {
    const jobUrls = ['/', '/pricing', '/jobs'];
    const teamUrls = ['/teams', '/verticals', '/client/templates'];
    const skilledUrls = ['/skilled-people'];

    this.jumpToFragment(url.split('#')?.[1]);

    let currentPathWithoutParams = url.split('#')[0];
    currentPathWithoutParams = currentPathWithoutParams.split('?')[0];

    if (
      this.utilities.stringStartWithAnyStringInArray(
        currentPathWithoutParams,
        jobUrls,
      )
    ) {
      this.headerData = {
        navLinks: jobHeaderNavLinks,
        dropDownLinks: [],
        showMenuToggleWidth: 1200,
        hideNavLinksWidth: 600,
      };
    } else if (
      this.utilities.stringStartWithAnyStringInArray(
        currentPathWithoutParams,
        skilledUrls,
      )
    ) {
      this.headerData = {
        navLinks: skilledLandingHeaderNavLinks,
        dropDownLinks: [],
        showMenuToggleWidth: 1200,
        hideNavLinksWidth: 600,
      };
    } else {
      this.headerData = {
        navLinks: defaultHeaderNavLinks,
        dropDownLinks: [],
        showMenuToggleWidth: 1200,
        hideNavLinksWidth: 1020,
      };
    }
  }

  jumpToFragment(fragment: string) {
    if (fragment) {
      this.utilities.scrollToAnchor(fragment, 0, {
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }

  isExternalLink(link: string) {
    return this.headerServices.isExternalLink(link);
  }
}
