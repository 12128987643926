import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BadgeComponent } from 'src/app/shared/badge/badge.component';
import { CompanyStatusComponent } from './company-status.component';

@NgModule({
  declarations: [CompanyStatusComponent],
  imports: [CommonModule, MatTooltipModule, BadgeComponent],
  exports: [CompanyStatusComponent],
})
export class CompanyStatusModule {}
