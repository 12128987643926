import { Injectable } from '@angular/core';
import { DefaultDataService, DefaultDataServiceConfig } from '@ngrx/data';
import { normalize } from 'normalizr';
import { CollectionStoreService } from 'src/ngrx/store/collection-store.service';
import { HttpInjectableStoreDataService } from './http-injectable-store.service';

@Injectable()
export abstract class StoreDataService<
  Entity,
  EntityNormalized,
> extends DefaultDataService<Entity> {
  constructor(
    entityName: string,
    private collectionService: CollectionStoreService<EntityNormalized>,
    private httpInjectableStoreDataService: HttpInjectableStoreDataService,
  ) {
    super(
      entityName,
      httpInjectableStoreDataService.http,
      httpInjectableStoreDataService.httpUrlGenerator,
    );
  }

  normalizeEntitiesAndAddToCache(entities: Entity[]) {
    if (!entities[0]) return [];
    const normalizedData = normalize(entities, [this.collectionService.schema]);
    this.collectionService.addNormalizedDataEntitiesToCache(normalizedData);
    const schema: any = this.collectionService.schema;
    return Object.values(normalizedData.entities[schema.key]);
  }
}

//   getAll(): Observable<Team[]> {
//     return super.getAll().pipe(map(heroes => heroes.map(hero => this.mapHero(hero))));
//   }

//   getById(id: string | number): Observable<Team> {
//     return super.getById(id).pipe(map(hero => this.mapHero(hero)));
//   }

//   getWithQuery(params: string | QueryParams): Observable<Team[]> {
//     return super.getWithQuery(params).pipe(map(heroes => heroes.map(hero => this.mapHero(hero))));
//   }

//   private mapHero(team: Team): Team {
//     return { ...team, dateLoaded: new Date() };
//   }
