<div
  *ngIf="!headerServices.isSideNavSticky"
  class="side-nav-header">
  <img
    (click)="sidenav.toggle()"
    *ngIf="sidenav?.opened"
    style="width: 18px; margin-right: 10px"
    src="assets/icons/close2.svg"
    alt="close icon" />

  <img
    src="assets/icons/logo.svg"
    routerLink="/"
    (click)="sidenav?.toggle()"
    width="125"
    alt="SupportFinity logo" />
</div>
<mat-nav-list
  class="nav-list"
  *ngIf="!headerServices.isFullscreenPage"
  [ngStyle]="{ padding: headerServices.isSideNavSticky ? '0px' : null }"
  [ngClass]="{ 'not-logged-in': !(headerServices.isLoggedIn$ | async) }">
  <div *ngIf="!(headerServices.isLoggedIn$ | async)">
    <app-header-anonymous-side-nav [sidenav]="sidenav"></app-header-anonymous-side-nav>
  </div>

  <ng-container *ngIf="headerServices.isLoggedIn$ | async">
    <app-header-customer-side-nav
      *ngIf="headerServices.isUserCustomer()"
      [sidenav]="sidenav">
    </app-header-customer-side-nav>
    <app-header-skilled-side-nav
      *ngIf="headerServices.isUserSkilled()"
      [sidenav]="sidenav">
    </app-header-skilled-side-nav>

    <div *ngIf="headerServices.isUserHr()">
      <a
        (click)="sidenav.toggle(); routeToLink('/hr/add-job')"
        routerLinkActive="active-link"
        href="/hr/add-job"
        style="cursor: pointer"
        mat-list-item>
        <img
          src="../../../assets/icons/my-teams.svg"
          alt="Human resourses dashboard" />
        My Dashboard
      </a>
    </div>
    <app-header-admin-side-nav
      *ngIf="headerServices.isUserAdmin()"
      [sidenav]="sidenav"></app-header-admin-side-nav>
  </ng-container>
</mat-nav-list>
