<div>
  <app-job-vacancy-text-search
    [clearAfterSearch]="true"
    class="job-search"
    (doSearch)="sidenav.close()" />
  <mat-accordion class="nav-bar-expansion">
    <mat-expansion-panel class="mat-expansion-parent">
      <mat-expansion-panel-header
        [collapsedHeight]="''"
        [expandedHeight]="''">
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16 disabled"
            [routerLink]="['/skilled-people/home/']"
            routerLinkActive="active"
            >Home</a
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-expansion-child-list">
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/home/latest-jobs']"
          routerLinkActive="active"
          >Latest Jobs</a
        >
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/home/recommended-jobs']"
          routerLinkActive="active"
          >Recommended Jobs</a
        >
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/home/saved-jobs']"
          routerLinkActive="active"
          >Saved Jobs</a
        >
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="mat-expansion-parent no-children"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16"
            (click)="sidenav.toggle()"
            [routerLink]="['/skilled-people/find-work/']"
            routerLinkActive="active"
            >Find Work</a
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-expansion-parent">
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16 disabled"
            [routerLink]="['/skilled-people/applications/']"
            routerLinkActive="active"
            >My Applications</a
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mat-expansion-child-list">
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/applications/pending/']"
          routerLinkActive="active"
          >In Progress</a
        >
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/applications/shortlisted/']"
          routerLinkActive="active"
          >Shortlisted</a
        >
        <a
          class="mat-expansion-child f-14"
          (click)="sidenav.toggle()"
          [routerLink]="['/skilled-people/applications/closed/']"
          routerLinkActive="active"
          >Closed</a
        >
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="mat-expansion-parent no-children"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16"
            (click)="sidenav.toggle()"
            [routerLink]="['/skilled-people/tests/']"
            routerLinkActive="active"
            >Tests</a
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel class="no-indicator">
      <mat-expansion-panel-header
        [collapsedHeight]="''"
        [expandedHeight]="''">
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16"
            (click)="sidenav.toggle()"
            routerLink="/find-salaries"
            routerLinkActive="active">
            <div class="expansion-panel-title">Find Salaries</div>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-expansion-parent no-children"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16"
            (click)="sidenav.toggle()"
            href="https://help.supportfinity.com"
            target="_blank">
            <div class="expansion-panel-title">Help</div>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="mat-expansion-parent no-children"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="expansion-panel-title">
          <a
            class="f-16"
            (click)="sidenav.toggle()"
            href="https://blog.supportfinity.com"
            target="_blank">
            <div class="expansion-panel-title">Blog</div>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div
  class="refer-wrapper"
  *ngIf="(headerServices.isLoggedIn$ | async) && headerServices.isUserSkilled()">
  <button
    mat-button
    type="button"
    (click)="openReferPopup()"
    class="btn-bk-black"
    >Invite friends</button
  >
</div>
