import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { SkeletonLoadingModule } from './../../../shared/loadings/skeleton-loading/skeleton-loading.module';
import { CompanyPackageCardComponent } from './company-package-card.component';
import { LabeledProgressBarModule } from 'src/app/shared/progress/labeled-progress-bar/labeled-progress-bar.module';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';

@NgModule({
  declarations: [CompanyPackageCardComponent],
  imports: [
    CommonModule,
    SkeletonLoadingModule,
    RouterModule,
    MatButtonModule,
    LabeledProgressBarModule,
    SvgIconModule,
  ],
  exports: [CompanyPackageCardComponent],
})
export class CompanyPackageCardModule {}
