import { animate, style, transition, trigger } from '@angular/animations';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-hire-overlay',
  templateUrl: './hire-overlay.component.html',
  styleUrls: ['./hire-overlay.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('200ms ease-in-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('200ms ease-in-out', style({ opacity: '0' })),
      ]),
    ]),
  ],
})
export class HireOverlayComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() originElement;
  @Input() darkOverlay = true;

  @ViewChild('hireContainerOverlay') hireContainerOverlay: TemplateRef<any>;

  jobTitle: string = null;
  hireOverlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {}

  toggleHireOverlay(jobTitle: string = null) {
    this.jobTitle = jobTitle;
    if (this.hireOverlayRef && this.hireOverlayRef.hasAttached())
      this.closeHireOverlay();
    else this.openHireOverlay();
  }

  closeHireOverlay() {
    if (this.hireOverlayRef) {
      this.hireOverlayRef.dispose();
    }
  }

  openHireOverlay() {
    if (this.hireOverlayRef && this.hireOverlayRef.hasAttached()) return;
    let positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.originElement)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'start', originY: 'bottom' },
          { overlayX: 'start', overlayY: 'top' },
          0,
          10,
        ),
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
          0,
          10,
        ),
        new ConnectionPositionPair(
          { originX: 'center', originY: 'top' },
          { overlayX: 'center', overlayY: 'bottom' },
          0,
          -10,
        ),
      ]);
    const config = new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: `cdk-overlay-${this.darkOverlay ? 'dark' : 'transparent'}-backdrop`,
    });

    const overlayRef: OverlayRef = this.overlay.create(config);

    this.hireOverlayRef = overlayRef;
    const tempPortal = new TemplatePortal(
      this.hireContainerOverlay,
      this._viewContainerRef,
    );
    overlayRef.attach(tempPortal);
    overlayRef.backdropClick().subscribe(() => {
      this.closeHireOverlay();
      overlayRef.dispose();
    });
  }
}
